import React from "react"
import { Link } from "gatsby"
import tw from "twin.macro" //eslint-disable-line
import { css } from "styled-components/macro" //eslint-disable-line
import BackIcon from "react-feather/dist/icons/chevron-left"

import { StaticLayout } from "../components/StaticLayout"

const NotFoundPage = () => (
  <StaticLayout headingText="404: Not found" tw="min-h-64 sm:min-h-72">
    <h2>OOPS!</h2>
    <p>The page you are looking for doesn&#39;t exist... Sorry.</p>
    <div tw="mt-4">
      <Link to="/" tw="text-primary-600 hocus:text-primary-700">
        <BackIcon tw="inline-block" size="18" /> Back to Home
      </Link>
    </div>
  </StaticLayout>
)

export default NotFoundPage
